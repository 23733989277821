<template>
    <div class="Contactus">
        <div class="about__content">
            <div class="link_to">
                <div class="link_banner"></div>
                <div class="flex">
                    <dl>
                        <dd>名称：陕西重构教育科技有限公司</dd>
                        <dd>地址：陕西省西安市高新区丈八街办创业广场1幢B1404室</dd>
                    </dl>
                    <dl style="width:200px">
                        <dd>710075</dd>
                        <dd>电话：029-88892465</dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>
<style scoped>
@import "../../styles/Page/Contactus.css";
</style>